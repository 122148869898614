<section class="profile" [ngClass]="{ 'mobile-hide': activeSection != 'profile' }">
    <div id="user-info">
        <h1>@{{ content?.username || auth.authUser.unique_name }}</h1>
        <p>Membro desde {{ content?.registeredAt | date:'MMMM \'de\' yyyy' : '-3' : 'pt-BR' }}</p>
    </div>

    <div id="progress" *ngIf="content?.statistics">
        <span>
            <span>{{ content?.statistics.lessons }}</span>
            <p>aulas acessadas</p>
        </span>

        <hr/>

        <span>
            <span>{{ content?.statistics.books }}</span>
            <p>livros lidos</p>
        </span>

        <hr/>

        <span>
            <span>{{ content?.statistics.courses }}</span>
            <p>Cursos em Andamento</p>
        </span>
    </div>

    <div id="interests">
        <h2 class="subtitle">Tópicos de Interesse</h2>
        <ul>
            <li *ngFor="let i of content?.interests">{{ i }}</li>
        </ul>

        <div class="section-emptystate" *ngIf="!content?.interests.length">
            <p>Registraremos aqui algumas palavras chaves dos cursos que interagir com maior frequência, assim poderá encontrar material relacionado mais facilmente</p>
        </div>
    </div>

    <div id="goals">
        <h2 class="subtitle">Metas & Objetivos</h2>
        <ul>
            <li>
                <div class="check" [ngClass]="{ 'active': content?.goals[0] }"></div>
                <div [ngClass]="{ 'completed': content?.goals[0] }">
                    <p>Matrícule-se e assista uma aula</p>
                    <p *ngIf="content?.goals[0]">Concluído!</p>
                </div>
            </li>
            <li>
                <div class="check" [ngClass]="{ 'active': content?.goals[1] }"></div>
                <div [ngClass]="{ 'completed': content?.goals[1] }">
                    <p>Leia seu primeiro livro</p>
                    <p *ngIf="content?.goals[1]">Concluído!</p>
                </div>
            </li>
            <li>
                <div class="check" [ngClass]="{ 'active': content?.goals[2] }"></div>
                <div [ngClass]="{ 'completed': content?.goals[2] }">
                    <p>Assista 30 aulas de qualquer curso</p>
                    <p *ngIf="content?.goals[2]">Concluído!</p>
                </div>
            </li>
            <li>
                <div class="check" [ngClass]="{ 'active': content?.goals[3] }"></div>
                <div [ngClass]="{ 'completed': content?.goals[3] }">
                    <p>Marque 10 livros como lidos</p>
                    <p *ngIf="content?.goals[3]">Concluído!</p>
                </div>
            </li>
            <li>
                <div class="check" [ngClass]="{ 'active': content?.goals[4] }"></div>
                <div [ngClass]="{ 'completed': content?.goals[4] }">
                    <p>Assista 500 aulas de qualquer curso</p>
                    <p *ngIf="content?.goals[4]">Concluído!</p>
                </div>
            </li>
        </ul>
    </div>

    <div id="reset">
        <button (click)="auth.signout()">Fazer Logout</button>
        <button (click)="isModalOpen = true" class="secondary">Limpar Histórico</button>
    </div>
</section>

<article class="initial" (scroll)="scrollHandler($event)" [ngClass]="{ 'mobile-hide': activeSection === 'profile' }">
    <atz-header></atz-header>
    
    <main #wrapper>
        <section *ngIf="!activeSection && content?.latest">
            <h1 class="title">Continue Assistindo</h1>
            <atz-watching [latest]="content?.latest"></atz-watching>
        </section>

        <section *ngIf="!activeSection || activeSection === 'recentCourses'">
            <div class="header-row">
                <h1 class="title">Cursos Recentes</h1>
                <button class="secondary" (click)="toggleActive('recentCourses')">
                    <i class="cil-list"></i>
                    <span>{{ activeSection === 'recentCourses' ? 'Minimizar' : 'Expandir' }}</span>
                </button>
            </div>
            <ul class="card-list three-itens" [ngClass]="{ 'limited': !activeSection }">
                <li *ngFor="let i of content?.recentCourses">
                    <atz-course-card [course]="i"></atz-course-card>
                </li>
            </ul>

            <div class="section-emptystate" *ngIf="!content?.recentCourses.length">
                <img src="../../../assets/images/empty_states/course.svg">
                <div>
                    <h4>Quer aprender algo novo?</h4>
                    <p>Sempre que acessar qualquer aula de qualquer curso, ele ficará registrado aqui para que você possa continuar facilmente de onde parou. Não é prático?</p>
                </div>
            </div>
        </section>

        <section *ngIf="!activeSection">
            <h1 class="title">Lidos Recentemente</h1>
            <ul class="swiper-container" id="recentBooks">
                <div class="swiper-wrapper" watchElement (DOMChange)="sliderChangeDetection($event)">
                    <li *ngFor="let i of content?.recentBooks" class="swiper-slide" (click)="navigate(null, i.id)">
                        <atz-book-card [book]="i"></atz-book-card>
                    </li>
                </div>
            </ul>

            <div class="section-emptystate" *ngIf="!content?.recentBooks.length">
                <img src="../../../assets/images/empty_states/books.svg">
                <div>
                    <h4>Como andam suas leituras?</h4>
                    <p>Assim que terminar de ler algum livro, clique na opção "Marcar como Lido" na própria página, e poderá ter fácil acesso a eles a partir dessa seção</p>
                </div>
            </div>
        </section>

        <section *ngIf="!activeSection || activeSection === 'favCourses'">
            <div class="header-row">
                <h1 class="title">Cursos Favoritos</h1>
                <button class="secondary" (click)="toggleActive('favCourses')">
                    <i class="cil-list"></i>
                    <span>{{ activeSection === 'favCourses' ? 'Minimizar' : 'Expandir' }}</span>
                </button>
            </div>
            <ul class="card-list three-itens" [ngClass]="{ 'limited': !activeSection }">
                <li *ngFor="let i of content?.favCourses">
                    <atz-course-card [course]="i"></atz-course-card>
                </li>
            </ul>

            <div class="section-emptystate" *ngIf="!content?.favCourses.length">
                <img src="../../../assets/images/empty_states/course.svg">
                <div>
                    <h4>Será que não há um curso que você possa gostar?</h4>
                    <p>Procure por cursos que te disperte interesse, e poderá registrá-los para serem acessados facilmente por essa seção ao tocar na estrela abaixo da descrição de cada curso</p>
                </div>
            </div>
        </section>

        <section *ngIf="!activeSection || activeSection === 'watchLater'">
            <div class="header-row">
                <h1 class="title">Assistir Mais Tarde</h1>
                <button class="secondary" (click)="toggleActive('watchLater')">
                    <i class="cil-list"></i>
                    <span>{{ activeSection === 'watchLater' ? 'Minimizar' : 'Expandir' }}</span>
                </button>
            </div>
            <ul class="card-list three-itens" [ngClass]="{ 'limited': !activeSection }">
                <li *ngFor="let i of content?.watchLater" (click)="navigate(i.id)">
                    <atz-lesson-card [image]="i.banner">
                        <span class="badge spaceless">{{ i.duration | timeduration }} m</span>
                        <p class="name small">{{ i.name }}</p>
                    </atz-lesson-card>
                </li>
            </ul>

            <div class="section-emptystate" *ngIf="!content?.watchLater.length">
                <img src="../../../assets/images/empty_states/books2.svg">
                <div>
                    <h4>Não precisa procurar por uma aula sempre que desejar acessá-la</h4>
                    <p>Você pode marcá-las para "Assistir Mais Tarde" na tela de cada aula e elas aparecerão aqui para assitir quantas vezes desejar</p>
                </div>
            </div>
        </section>

        <section *ngIf="!activeSection">
            <h1 class="title">Seus livros favoritos</h1>
            <ul class="swiper-container" id="favBooks">
                <div class="swiper-wrapper" watchElement (DOMChange)="sliderChangeDetection($event)">
                    <li *ngFor="let i of content?.favBooks" class="swiper-slide" (click)="navigate(null, i.id)">
                        <atz-book-card [book]="i"></atz-book-card>
                    </li>
                </div>
            </ul>

            <div class="section-emptystate" *ngIf="!content?.favBooks.length">
                <img src="../../../assets/images/empty_states/books.svg">
                <div>
                    <h4>Gostou muito de um livro? Não o perca nunca mais</h4>
                    <p>Você pode marcar um livro como "Favorito" em suas respectivas páginas para que você possa vê-lo aqui quando precisar</p>
                </div>
            </div>
        </section>

        <section *ngIf="activeSection === 'favBooks'">
            <h1 class="title">Minhas Leituras</h1>
            <ul class="card-list four-itens">
                <li *ngFor="let i of content?.favBooks" class="swiper-slide" (click)="navigate(null, i.id)">
                    <atz-book-card [book]="i" [isScaled]="true"></atz-book-card>
                </li>
            </ul>
        </section>
    </main>
</article>

<section class="modal-overlay" *ngIf="isModalOpen">
    <div class="clear-modal">
        <h1>Limpar Histórico</h1>
        <p>Você pode limpar os seus dados de navegação em um intervalo de tempo específico, tanto de seus livros quanto de suas aulas. É importante lembrar que essa ação NÃO é reversível.</p>

        <form>
            <label>Data Mínima</label>
            <label>Data Máxima</label>
            <input type="date" placeholder="Data Mínima" #mindate>
            <input type="date" placeholder="Data Máxima" #maxdate>
        </form>
        <div class="actions">
            <div>
                <input type="checkbox" #books><label>Remover Livros</label><br/>
                <input type="checkbox" #lessons><label>Remover Aulas</label><br/>
            </div>
            <div class="buttons">
                <button (click)="isModalOpen = false" class="secondary">Cancelar</button>
                <button (click)="clearHistory(mindate.value, maxdate.value, lessons.checked, books.checked)">Confirmar Exclusão</button>
            </div>
        </div>
    </div>
</section>