<aside *ngIf="isEnabled">
    <div class="avatar" [routerLink]="['/home']">{{ user }}</div>
    <ul>
        <li routerLinkActive="active" routerLink="/home" [routerLinkActiveOptions]="{ exact: true }">
            <i class="cil-contact scale"></i>
            <span class="menu-label">Inicial</span>
        </li>
        <li routerLinkActive="active" routerLink="/favCourses">
            <i class="cil-star scale"></i>
            <span class="menu-label">Favoritos</span>
        </li>
        <li routerLinkActive="active" routerLink="/watchLater">
            <i class="cil-clock scale"></i>
            <span class="menu-label">A Assistir</span>
        </li>
        <li routerLinkActive="active" routerLink="/favBooks">
            <i class="cil-notes scale"></i>
            <span class="menu-label">Leituras</span>
        </li>
    </ul>
</aside>