<div [routerLink]="['/home']">
    <img src="../../../assets/images/dark_logo.png" class="logo" [ngClass]="{ 'active': !isFormActive }">
</div>
<form (submit)="search($event, searchbox.value)" (click)="showInput(searchbox)">
    <i class="cil-magnifying-glass"></i>
    <input type="search" placeholder="Busque por um mundo..." [ngClass]="{ 'active': isFormActive }" (blur)="isFormActive = false" #searchbox>
</form>
<div>
    <i class="cil-room scale desktop"></i>
    <i class="cil-mood-good scale mobile" routerLink="/profile"></i>
</div>