<atz-header></atz-header>

<main #search_wrapper>
    <h1 class="title">Resultado da sua busca por "{{ query }}"</h1>

    <div class="filters">
        <ul class="tabs">
            <li [ngClass]="{ 'active': !opts.tab, 'blocked': (opts.watcheds || opts.toWatchLater) }" (click)="updateOptions({ tab: null })">Todos</li>
            <li [ngClass]="{ 'active': opts.tab === 'c', 'blocked': (opts.watcheds || opts.toWatchLater) }" (click)="updateOptions({ tab: 'c' })">Cursos</li>
            <li [ngClass]="{ 'active': opts.tab === 'm', 'blocked': (opts.watcheds || opts.toWatchLater) }" (click)="updateOptions({ tab: 'm' })">Módulos</li>
            <li [ngClass]="{ 'active': opts.tab === 'l' }" (click)="updateOptions({ tab: 'l' })">Aulas</li>
            <li [ngClass]="{ 'active': opts.tab === 'b', 'blocked': (opts.watcheds || opts.toWatchLater) }" (click)="updateOptions({ tab: 'b' })">Livros</li>
        </ul>

        <div class="toggles">
            <i class="cil-history" [ngClass]="{ 'active': opts.watcheds }" (click)="updateOptions({ watcheds: !opts.watcheds })"></i>
            <i class="cil-clock" [ngClass]="{ 'active': opts.toWatchLater }" (click)="updateOptions({ toWatchLater: !opts.toWatchLater })"></i>
            <i class="cil-star" [ngClass]="{ 'active': opts.favoriteds }" (click)="updateOptions({ favoriteds: !opts.favoriteds })"></i>
        </div>
    </div>

    <nav class="list-toggle" *ngIf="!opts.tab">
        <ul class="tabs">
            <li [ngClass]="{ 'active': opts.mobileTab === 'VID' }" (click)="opts.mobileTab = 'VID'">Vídeos</li>
            <li [ngClass]="{ 'active': opts.mobileTab === 'LEC' }" (click)="opts.mobileTab = 'LEC'">Leituras</li>
        </ul>
    </nav>

    <section class="results">
        <ul class="courses" *ngIf="content.length && opts.tab !== 'b'" [ngClass]="{ 'mobile-active': opts.mobileTab == 'VID' }">
            <a *ngFor="let i of content" href="view?course={{i.course}}&module={{i.module}}&lesson={{i.lesson}}">
                <li>
                    <div class="banner">
                        <img [src]="i.banner">
                        <div *ngIf="i.amount" class="module-badge">
                            <i class="cil-layers"></i>
                            <span>{{ i.amount | padstart: 2 }}</span>
                        </div>
                    </div>

                    <div class="data">
                        <div class="item-duration" *ngIf="i.duration">{{ i.duration }}</div>
                        <div class="item-title">{{ i.name }}</div>
                        <div class="item-subtitle" *ngIf="i.label">{{ i.label }}</div>
                    </div>
                </li>
            </a>
        </ul>

        <ul class="books" *ngIf="books.length && (opts.tab === 'b' || opts.tab === null)" [ngClass]="{ 'mobile-active': opts.mobileTab == 'LEC' }">
            <a *ngFor="let i of books" href="read?book={{i.id}}">
                <li class="book-item">
                    <img [src]="i.cover" class="scale" [title]="i.title + ' - ' + i.author">
                    <div class="readed" *ngIf="i.wasReaded"><i class="cil-check-circle"></i></div>
                    <div class="pages">{{ i.pages }} pgs</div>
                </li>
            </a>
        </ul>
    </section>

    <section class="emptystate" 
        *ngIf="!content?.length && !books.length || 
            opts.tab == 'b' && !books.length ||
            opts.tab == null && !content?.length && !books.length || 
            (opts.tab == 'c' || opts.tab == 'm') && !content.length || 
            opts.mobileTab == 'LEC' && !books.length">
        <img src="../../../assets/images/empty_states/search.svg">
        <div>
            <h1>Oops...</h1>
            <p>Parece que ainda não temos nada sobre "{{ query }}" em nosso catálogo. Talvez se você procurar com menos palavras ou algo diferente possamos encontrar o que deseja :)</p>
        </div>
    </section>
</main>