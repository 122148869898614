<atz-lesson-card [width]="48" [image]="content?.banner" (click)="lesson.navigate(null, null, content?.courseId)">
    <p class="name">{{ content?.course }}</p>
</atz-lesson-card>

<div class="lesson">
    <p class="module">{{ content?.module }}</p>
    <h1 class="name">{{ content?.name }}</h1>
    <div class="badges">
        <span class="badge">Aula {{ content?.position | padstart : 3 }}</span>
        <span class="badge dark">{{ content?.duration | timeduration }} min</span>
    </div>
    <div class="actions">
        <button (click)="lesson.navigate(content?.id, null, content?.courseId)"><i class="cil-media-play"></i> Assistir Agora</button>
        <button (click)="lesson.addToWatchLater(content?.id)" class="secondary">Assistir Mais Tarde</button>
    </div>
</div>