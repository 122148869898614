<main>
    <section>
        <h1>Abra as portas para o conhecimento</h1>
        <p>Aprenda dentre uma grande variedade de conteúdo entre cursos, livros, podcasts, totalmente grátis.</p>
    </section>
</main>

<aside>
    <img class="logo" src="../../../assets/images/dark_logo.png" />
    <form [formGroup]="formLogin" (ngSubmit)="login()">
        <input name="username" type="text" placeholder="Nome de Usuário" formControlName="username"/>
        <input name="password" type="password" placeholder="Senha" formControlName="password"/>
        <input name="send" type="submit" value="Fazer Login" class="btn"/>
    </form>
    <hr/>
</aside>