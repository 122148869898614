<div class="container scale" [ngClass]="{ 'scale-wrapper': isScaled }">
    <img [src]="book.cover" *ngIf="isScaled" class="blurred">
    <img [src]="book.cover" [ngClass]="{ 'scale': isScaled }">
</div>

<div class="description">
    <p class="author">{{ book.author }}</p>
    <h3 class="name">{{ book.title }}</h3>
    <p *ngIf="book.subtitle">{{ book.subtitle }}</p>
    <span class="badge dark">{{ book.pages }} pag</span>
</div>