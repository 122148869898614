<atz-header class="mobile" bg-color="#202020"></atz-header>

<article class="lesson" *ngIf="lesson">
    <div class="wrapper">
        <section class="info">
            <img src="../../../assets/images/dark_logo.png" class="logo">

            <div class="data">
                <p class="module">{{ lesson.module }}</p>
                <h1 class="name">{{ lesson.name }}</h1>
                <div>
                    <span class="badge">Aula {{ lesson.position | padstart: 3 }}</span>
                    <span class="badge dark">{{ lesson.duration | timeduration }} min</span>
                </div>

                <div class="actions">
                    <button (click)="navigate(lesson.id - 1)" [disabled]="lesson.position <= 1"><i class="cil-media-step-backward"></i></button>
                    <button (click)="navigate(lesson.id + 1)" [disabled]="lesson.position >= getLessonAmount()"><i class="cil-media-step-forward"></i></button>
                </div>
            </div>

            <div plyr 
                class="video-player mobile" 
                [plyrSources]="lesson.video" 
                [plyrPoster]="lesson.banner" 
                [plyrTitle]="lesson.name" 
                (plyrEnterFullScreen)="lockScreen()" 
                (plyrExitFullScreen)="unlockScreen()">
            </div>

            <div class="track">
                <div class="about-track">
                    <div class="detail">
                        <label>Tamanho:</label>
                        <p>{{ lesson.size | filesize }}</p>
                    </div>
        
                    <hr/>

                    <div class="detail">
                        <label>Formato:</label>
                        <p>{{ lesson.link | fileformat }}</p>
                    </div>
                </div>
                
                <div class="track-actions">
                    <a class="btn" download="{{ lesson.name }}" [href]="lesson.link"><i class="cil-cloud-download"></i> <span>Fazer Download</span></a>
                    <button class="secondary" (click)="addToWatchLater()">
                        <span>Assistir Mais Tarde</span>
                        <i class="mobile cil-clock"></i>
                    </button>
                </div>
            </div>
        </section>

        <div plyr class="video-player desktop" [plyrSources]="lesson.video" [plyrPoster]="lesson.banner" [plyrTitle]="lesson.name"></div>
    </div>
</article>

<article class="course">
    <section>
        <div class="info">
            <span id="about">Sobre o Curso</span>
            <h1 class="name">{{ course?.name }}</h1>
            <p class="module">{{ course?.teacher}}</p>

            <p class="description">{{ course?.description }}</p>
            <div>
                <span class="badge">{{ getLessonAmount() | padstart: 3 }} aulas</span>
                <span class="badge primary">{{ getModuleDuration() | durationconcat }}</span>
            </div>
            <div class="actions">
                <button (click)="shareIt()"><i class="cil-share-alt"></i></button>
                <button (click)="favoriteIt()"><i class="cil-star"></i></button>
            </div>
        </div>
        <div class="banner" [ngStyle]="{'background-image': 'url(' + course?.banner + ')'}"></div>
    </section>

    <nav class="mobile">
        <ul class="tabs">
            <li [ngClass]="{ 'active': !lessonTabActive }" (click)="lessonTabActive = false">Módulos</li>
            <li [ngClass]="{ 'active': lessonTabActive }" (click)="lessonTabActive = true">Aulas</li>
        </ul>
    </nav>

    <section class="content">
        <ul class="modules" [ngClass]="{ 'mobile-hidden': lessonTabActive }">
            <li *ngFor="let i of course?.modules" (click)="navigate(null, i.id); lessonTabActive = true" [ngClass]="{ 'active': i.id == currentModule }">
                <div>
                    <p>{{ i.name }}</p>
                    <span>{{ i.duration | timeduration }}</span>
                </div>
                <div class="amount">
                    <i class="cil-screen-desktop"></i>
                    <span>{{ i.lessonsAmount | padstart: 2 }}</span>
                </div>
            </li>
        </ul>

        <ul class="lessons" [ngClass]="{ 'mobile-hidden': !lessonTabActive }">
            <li *ngFor="let i of module" (click)="navigate(i.id)">
                <div class="completion" [ngClass]="{ 'hide': !i.wasWatched }">
                    <i class="cil-check-circle"></i>
                </div>
                <div class="information">
                    <p>{{ i.name }}</p>
                    <span>{{ i.duration }}</span>
                </div>
                <div class="actions">
                    <button (click)="breakBubbling($event)" (click)="acessLesson(i.id, true)"><i class="cil-cloud-download"></i></button>
                    <button (click)="breakBubbling($event)" (click)="acessLesson(i.id)"><i class="cil-check-circle"></i></button>
                    <button (click)="breakBubbling($event)" (click)="addToWatchLater(i.id)"><i class="cil-clock"></i></button>
                </div>
            </li>
            
            <section class="emptystate" *ngIf="!module">
                <img src="../../../assets/images/empty_states/module.svg">
                <h1>Selecione um módulo da lista ao lado para ver as aulas que ele contém</h1>
                <p>A divisão por módulos ajuda o curso a ficar mais organizado :)</p>
            </section>
        </ul>
    </section>
</article>